.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: auto;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 14px;
      color: rgb(160, 160, 160);
      margin-bottom: 10px;
    }

    .link {
      width: max-content;
      font-size: 14px;
      border-bottom: 1px solid gray;
      margin-top: 10px;
    }

    .icon {
      font-size: 18px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
    }

    .items {
      list-style: none;
      padding-left: 20px;
      font-size: 14px;

      li {
        position: relative;
        margin-bottom: 5px;

        &::before {
          content: "";
          position: absolute;
          left: -15px;
          top: 50%;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: gray;
        }
      }
    }
  }
}
