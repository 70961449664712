.featured {
  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  border-radius: 10px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;

    .title {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .bottom {
    padding: 20px;
    padding-bottom: 0; // adjust padding here
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .featuredItem {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;

      .itemContent {
        text-align: left;

        .title {
          margin-bottom: 10px; // Add margin bottom to the title
        }

        .featuredChart {
          width: 90px; // Revert to original size
          height: 90px; // Revert to original size
          margin-bottom: 15px;
        }
      }
    }

    .title {
      font-weight: 500;
      color: gray;
    }

    .amount {
      font-size: 30px;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      color: gray;
      text-align: center;
    }
  }
}
